import React from "react"
import Layout from "../components/layout"
import Card from "../components/card"
import CardLayout from "../components/cardLayout"
import ExternalLink from "../components/externalLink"
import megaphone from "../images/megaphone.svg"
import creditCard from "../images/creditCard.svg"
import pencil from "../images/pencil.svg"
import envelope from "../images/envelope.svg"
import sparkles from "../images/sparkles.svg"
import styles from "./index.module.css"

const donateLinks = [
  { name: "City Bloom", href: "https://www.citybloom.org/" },
  {
    name: "Justice for Amani Kildea",
    href:
      "https://www.change.org/p/morristown-police-department-justice-for-amani-kildea/psf/promote_or_share",
  },
  {
    name: "Iron Bound Community Corporation",
    href: "https://ironboundcc.salsalabs.org/iccgeneraldonations/index.html",
  },
]

const petitionLinks = [
  {
    name: "Black Lives Matter: Defund the Police",
    href: "https://blacklivesmatter.com/defundthepolice/",
  },
  {
    name: "Justice for Breonna Taylor",
    href: "https://www.change.org/p/andy-beshear-justice-for-breonna-taylor",
  },
  { name: "For Breonna Taylor", href: "https://www.forbreonna.com/" },
]

export default function Home() {
  return (
    <Layout>
      <div className={styles.headingContainer}>
        <img className={styles.megaphone} src={megaphone} alt="" />
        <p className={styles.heading}>
          Our goal is to encourage you to use our{" "}
          <span className={styles.highlight}>combined giving power</span> to
          support ongoing{" "}
          <span className={styles.highlight}>
            anti-racist and racial justice efforts
          </span>{" "}
          in Livingston and New Jersey.
        </p>
      </div>
      <div className={styles.cardContainer}>
        <Card className={styles.smallCard} to="/community">
          <CardLayout headerImageSrc={sparkles}>
            <div className={styles.cardLayout}>
              <div>
                <h3 className={styles.cardHeader}>Community</h3>
                  <ExternalLink to="https://docs.google.com/forms/d/e/1FAIpQLSfzwDZ2DhQlr5w69fyIBegL9tO4S-2a8Toj0LXsHHpqt5VEAQ/viewform" className="margin-bottom--sm d-block">
                    LHS’s Black Student Union’s List of Inquiries and Demands
                  </ExternalLink>
                  <ExternalLink to="https://www.instagram.com/livingstonbsu/" className="margin-bottom--sm d-block">
                    @livingstonbsu
                  </ExternalLink>

                <p className="margin-bottom--sm">
                  Accounts of Racism and Microagressions in Livingston:
                </p>
                  <ExternalLink to="https://www.instagram.com/blackatlivingston/" className="margin-bottom--sm d-block">
                    @blackatlivingston
                  </ExternalLink>
              </div>

              <div className="margin-top--sm">
                <strong>
                  Learn More<span className={styles.arrow}>→</span>
                </strong>
              </div>
            </div>
          </CardLayout>
        </Card>

        <Card className={styles.smallCard} to="/donate">
          <CardLayout headerImageSrc={creditCard}>
            <div className={styles.cardLayout}>
              <div>
                <h3 className={styles.cardHeader}>Donate</h3>
                <ul className={styles.list}>
                  {donateLinks.map(({ name, href }) => (
                    <li key={name}>
                      <ExternalLink to={href}>{name}</ExternalLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="margin-top--sm">
                <strong>
                  See More<span className={styles.arrow}>→</span>
                </strong>
              </div>
            </div>
          </CardLayout>
        </Card>

        <Card className={styles.smallCard} to="/write">
          <CardLayout headerImageSrc={envelope}>
            <div className={styles.cardLayout}>
              <div>
                <h3 className={styles.cardHeader}>
                  Write Your Elected Officials
                </h3>
                <p>
                  Write to your elected officials with our automatically
                  generated e-mail templates.
                </p>
                <p>
                  Remember to personalize the message with an introduction and
                  sign your name.
                </p>
              </div>
              <div className="margin-top--sm">
                <strong>
                  Write Them<span className={styles.arrow}>→</span>
                </strong>
              </div>
            </div>
          </CardLayout>
        </Card>

        <Card className={styles.smallCard} to="/petition">
          <CardLayout headerImageSrc={pencil}>
            <div className={styles.cardLayout}>
              <div>
                <h3 className={styles.cardHeader}>Sign Petitions</h3>
                <ul className={styles.list}>
                  {petitionLinks.map(({ name, href }) => (
                    <li key={name}>
                      <ExternalLink to={href}>{name}</ExternalLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="margin-top--sm">
                <strong>
                  See More<span className={styles.arrow}>→</span>
                </strong>
              </div>
            </div>
          </CardLayout>
        </Card>
      </div>
    </Layout>
  )
}
